import { createStore } from 'vuex'
export default createStore({
  state: {
    appBaseUrl: null,
        appPath: null,
        window: {
            width: 0,
            height: 0
        },
        appMode: {},
        singleton:null,
        currentDateTime: {},
        currentDate:null,
        selectedDate:{},
        links:[],
        vmenuActive: false,
        vmenuTop: 35,
        vmenuLeft: 1,
        hactive: true,      
        selectedHmenuItem: null,
        mouseOverHmenuItem: null,
        menuMouseEvent: 0,
        menuPath: null,
        mainComponentName: 'DashBoard',
        selectedDayDiv: null,
        selectedMonthItem: null,
        selectedDayItem: null,
        signinFormActive: false,
        signoutFormActive: false,
        eventFormPosX: 300,
        eventFormPosY: 50,
        signinMessage: "Enter username and password",
        userId: 0,
        eventFormActive: true,
        eventPrompt: 'test message',
        firstHeaderMenu:[
            {
                id: 1,
                name: 'Signin',
                componentName: 'Signin'
            }
        ],
        noteList: [],
        zodiacSigns: null,
        cities: null
  },
  mutations: {
        appBaseUrl(state, payload){
            state.appBaseUrl = payload;
       },
        appPath(state, payload){
            state.appPath = payload;
        },
        windowHeight(state, payload){
            state.window.height = payload;
        },
        windowWidth(state, payload){
            state.window.width = payload;
        },
        appMode(state, payload){
            state.appMode = payload;
        },
        singleton(state, payload){
            state.singleton = payload;
        },
        userId(state, payload){
            state.userId = payload;
        },
        currentDate(state, payload){
            state.currentDate = payload;
        },
        currentDateTime(state, payload){
             state.currentDateTime = payload;
        },
        selectedDate(state, payload){
            state.selectedDate =  payload;
        },
        links(state, payload){
            state.links = payload;
        },
        vmenuActive(state, payload){
            state.vmenuActive = payload;
        },
        vmenuTop(state, payload){
            state.vmenuTop = payload;
        },
        vmenuLeft(state, payload){
            state.vmenuLeft = payload;
        },
        selectedHmenuItem(state, payload){
            state.selectedHmenuItem = payload;
        },
        mouseOverHmenuItem(state, payload){
            state.mouseOverHmenuItem = payload;
        },
        menuMouseEvent(state, payload){
            state.menuMouseEvent = payload;
        },
        menuPath(state, payload){
            state.menuPath = payload;
        },
        mainComponentName(state, payload){
            state.mainComponentName = payload;
        },
        selectedMonthItem(state, payload){
            state.selectedMonthItem = payload;
        },
        selectedDayItem(state, payload){
            state.selectedDayItem = payload;
        },
        signinFormActive(state, payload){
            state.signinFormActive = payload;
        },
        eventFormPos(state, payload){
            state.eventFormPosX = payload.x;
            state.eventFormPosY = payload.y;
        },
        signinMessage(state, payload){
            state.signinMessage = payload;
        },
        signoutFormActive(state, payload){
            state.signoutFormActive = payload;
        },
        // userid(state, payload){
        //     state.userid = payload;
        // },
        eventFormActive(state, payload){
            state.eventFormActive = payload;
        },
        eventPrompt(state, payload){
            state.eventPrompt = payload;
        },
        // addNote(state, payload){
        //     if (state.noteList.length != 0)
        //     {            
        //         const found: any = state.noteList.find((temp: any) =>{
        //             if (temp.day === payload.day)
        //                 return temp;                        

        //         });
        //         if (found){
        //             found.notes.push(payload.note);
        //         }else{
        //             const obj: any = {
        //                 day: payload.day,
        //                 notes:[payload.note]
        //             };
        //             state.noteList.push(obj);
        //         }
                
        //     }else{
        //         const obj = {
        //             day: payload.day,
        //             notes:[payload.note]
        //         };
        //         state.noteList.push(obj);
        //     }
        // }   

        zodiacSigns(state, payload){
            state.zodiacSigns = payload;
        },
        cities(state, payload){
            state.cities = payload;
        }
  },
  actions: {
  },
  modules: {
  }
})

