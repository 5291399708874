import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

function lazyLoad(view: any){
  //return() => import(`@/views/${view}.vue`)
  return() => import(`@/components/${view}.vue`)
}
const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    name: 'allCatch',
    component: () => import('@/views/LivppyLab.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
