<template>
  <div>
    <router-view/>
  </div>
</template>
<script>
export default {

  methods:{
    handleResize() {
      this.$store.commit('windowHeight', window.innerHeight);
      this.$store.commit('windowWidth', window.innerWidth);
    }
  },
  components:{
  },    
  created(){
    window.addEventListener('resize', this.handleResize)
    this.handleResize(); 
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
